
import * as Components from "./Verdugopizza"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "verdugopizza"
}

